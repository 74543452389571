import { useGSAP } from "@gsap/react";
import gsap from "gsap";
import { Draggable } from "gsap/all";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
const Header = () => {
  const [menu, setMenu] = useState(false);
  const [showService, setShow] = useState(false);
  const location = useLocation();
  const isActiveChildPage = location.pathname.startsWith("/services/");
  const handleMenu = () => {
    setMenu(!menu);
  };
  useLayoutEffect(() => {
    function verticalLoop(elements, speed) {
      elements = gsap.utils.toArray(elements);
      let firstBounds = elements[0].getBoundingClientRect(),
        lastBounds = elements[elements.length - 1].getBoundingClientRect(),
        inverted = firstBounds.top > lastBounds.top,
        topBounds = inverted ? lastBounds : firstBounds,
        bottomBounds = inverted ? firstBounds : lastBounds,
        top =
          topBounds.top -
          topBounds.height -
          Math.abs(
            elements[inverted ? elements.length - 2 : 1].getBoundingClientRect()
              .top - topBounds.bottom
          ),
        bottom = bottomBounds.top,
        distance = bottom - top,
        duration = Math.abs(distance / speed),
        tl = gsap.timeline({ repeat: -1 }),
        plus,
        minus;
      if (inverted) {
        speed = -speed;
        bottom += Math.abs(
          elements[inverted ? elements.length - 2 : 1].getBoundingClientRect()
            .top - topBounds.top
        );
      }
      plus = speed < 0 ? "-=" : "+=";
      minus = speed < 0 ? "+=" : "-=";
      elements.forEach((el, i) => {
        let bounds = el.getBoundingClientRect(),
          ratio = Math.abs((bottom - bounds.top) / distance);
        if (speed < 0 !== inverted) {
          ratio = 1 - ratio;
        }
        tl.to(
          el,
          {
            y: plus + distance * ratio,
            duration: duration * ratio,
            ease: "none"
          },
          0
        );
        tl.fromTo(
          el,
          {
            y: minus + distance
          },
          {
            y: plus + (1 - ratio) * distance,
            ease: "none",
            duration: (1 - ratio) * duration,
            immediateRender: false
          },
          duration * ratio
        );
      });
      return tl;
    }
    gsap.utils.toArray(".text-row").forEach((row, i) => {
      const words = row.querySelectorAll(".text-item");
      menu && verticalLoop(words, 100);
    });
  });
  useGSAP(() => {
    const card = document.querySelectorAll(".menu-card");
    card.forEach((c) => {
      const images = c.querySelectorAll(".images");
      const childImg1 = c.querySelectorAll(".images img:nth-child(1)");
      const childImg2 = c.querySelectorAll(".images img:nth-child(2)");
      const childImg3 = c.querySelectorAll(".images img:nth-child(3)");
      const text = c.querySelectorAll(".card h4");
      const tl = gsap.timeline({
        paused: true,
        defaults: {
          ease: "back.out(1)",
          duration: 1
        }
      });
      tl.from(images, {
        rotate: -30,
        yPercent: 170
      })
        .fromTo(
          childImg1,
          {
            rotate: -30
          },
          {
            delay: 0.2,
            rotate: 0
          },
          "<"
        )
        .fromTo(
          childImg2,
          {
            rotate: -30
          },
          {
            rotate: 10
          },
          "<"
        )
        .fromTo(
          childImg3,
          {
            rotate: -30
          },
          {
            rotate: 16
          },
          "<"
        )
        .to(
          c,
          {
            border: "1px solid #B1FA63",
            color: "#fff"
          },
          "<"
        )
        .to(
          text,
          {
            color: "#B1FA63"
          },
          "<"
        );
      // const card = document.querySelector(".card");
      c.addEventListener("mouseover", function () {
        tl.timeScale(1).play();
      });
      c.addEventListener("mouseleave", function () {
        tl.timeScale(2).reverse();
      });
    });
  });
  useEffect(() => {
    menu
      ? document.querySelector("body").classList.add("overflow-hidden")
      : document.querySelector("body").classList.remove("overflow-hidden");
  }, [menu]);
  return (
    <>
      <header className="bg-[#141416] fixed top-0 w-screen z-10 ">
        <div className="wrapper flex justify-between items-center h-[66px] md:h-[98px] max-w-[1680px] mx-auto">

          <div className="max-lg:hidden">
            <Link to="mailto:contact@solusent.com" className="inline-block ">
              contact@solusent.com
            </Link>
            <span className="text-[rgba(255,255,255,0.5)] inline-block px-2 separator">
              {" "}
              |{" "}
            </span>
            <a href="tel:0203-1938-835" className="text-primary number">
              0203 1938 835
            </a>
          </div>
          <div className="logo lg:absolute lg:left-1/2 lg:-translate-x-1/2">
            <Link to="/">
              <img
                src="/images-new/solusent-logo.svg"
                alt="Solusent Logo"
                width="151"
                className="max-md:w-[100px]"
              />
            </Link>
          </div>
          <div className="w-[30px] h-[30px] md:w-[50px] md:h-[50px]">
            <button
              className={`absolute group ${menu && "active"
                }  border rounded-full p-[8px] md:p-[14px] `}
              onClick={handleMenu}
            >
              <div className="line-wrap flex flex-col justify-between w-[12px] h-[12px] md:w-[20px] md:h-[20px] transform transition-all duration-300 origin-center">
                <div className="line-1 bg-white h-[2px] w-1/2 rounded transform transition-all duration-300 origin-right delay-75 "></div>
                <div className="line-2 bg-white h-[2px] rounded"></div>
                <div className="line-3 bg-white h-[2px] w-1/2 rounded self-end transform transition-all duration-300 origin-left delay-75 "></div>
              </div>
            </button>
          </div>
        </div>
      </header>
      <div
        className={`main-menu w-screen no-scrollbar ${menu ? "h-screen" : "h-[0vh]"
          } duration-300 overflow-y-auto fixed top-0 z-[111] bg-[rgba(20,20,22,0.3)] backdrop-blur-[44px] overflow-x-hidden`}
        data-lenis-prevent
      >
        <div className="menu-wrap max-w-[1253px] min-h-screen  bg-black ml-auto flex flex-wrap items-start">
          <div className="text-container max-lg:!hidden">
            <div className="text-row font-bold will-change-content">
              <span className="text-item will-change-transform">
                Design to Impress, Develop to perform
              </span>
              <span className="text-item will-change-transform">
                Design to Impress, Develop to perform
              </span>
            </div>
          </div>
          <div className="px-5 xl:px-14 flex-1 min-h-screen flex flex-col md:justify-between">
            <div className="menu-header  h-[66px] md:h-[98px] flex items-center justify-between border-b border-[#949494]">
              <div className="logo ">
                <Link to="/">
                  <img
                    src="/images-new/solusent-logo.svg"
                    alt="Solusent Logo"
                    width="151"
                    className="max-md:w-[100px]"
                  />
                </Link>
              </div>
              <div className="w-[30px] h-[30px] md:w-[50px] md:h-[50px]">
                <button
                  className={`absolute group ${menu && "active"
                    }  border rounded-full p-[8px] md:p-[14px] `}
                  onClick={handleMenu}
                >
                  <div className="line-wrap flex flex-col justify-between w-[12px] h-[12px] md:w-[20px] md:h-[20px] transform transition-all duration-300 origin-center">
                    <div className="line-1 bg-white h-[2px] w-1/2 rounded transform transition-all duration-300 origin-right delay-75 "></div>
                    <div className="line-2 bg-white h-[2px] rounded"></div>
                    <div className="line-3 bg-white h-[2px] w-1/2 rounded self-end transform transition-all duration-300 origin-left delay-75 "></div>
                  </div>
                </button>
              </div>
            </div>
            <div className="menu-body mt-[34px] flex border-b border-[#949494] pb-4">
              <ul className="text-[26px] md:text-[50px] flex-1 flex flex-col gap-4">
                <li>
                  <Link
                    to="/"
                    className={`h-[28px] md:h-[56px] overflow-hidden inline-block group leading-[1] ${location.pathname === "/" ? "!text-primary" : undefined
                      }`}
                  >
                    <div className="group-hover:-translate-y-[28px] md:group-hover:-translate-y-[56px] duration-300 will-change-transform !text-white hover:!text-primary">
                      <div>Home</div>
                      <div>Home</div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about"
                    className={`h-[28px] md:h-[56px] overflow-hidden inline-block group leading-[1] ${location.pathname === "/about"
                      ? "!text-primary"
                      : undefined
                      }`}
                  >
                    <div className="group-hover:-translate-y-[28px] md:group-hover:-translate-y-[56px] duration-300 will-change-transform !text-white hover:!text-primary">
                      <div>About</div>
                      <div>About</div>
                    </div>
                  </Link>
                </li>
                <li className="md:hidden relative">
                  <div
                    className={`h-[28px] md:h-[56px] overflow-hidden inline-block group leading-[1] ${isActiveChildPage ? "!text-primary" : undefined
                      }`}
                    onClick={() => setShow(!showService)}
                  >
                    <div className=" ">
                      <div className="group-hover:-translate-y-[28px] md:group-hover:-translate-y-[56px] duration-300 will-change-transform !text-white hover:!text-primary">
                        <div>Services</div>
                        <div>Services</div>
                      </div>
                    </div>
                    <span className="absolute right-0 top-3">
                      <svg
                        width="14"
                        height="8"
                        viewBox="0 0 14 8"
                        fill="none"
                        className={`w-5 duration-300 ${showService ? "-rotate-180" : undefined
                          }`}
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M7.7071 7.70734C7.51957 7.89481 7.26526 8.00013 7.0001 8.00013C6.73493 8.00013 6.48063 7.89481 6.2931 7.70734L0.636099 2.05034C0.540589 1.9581 0.464406 1.84775 0.411997 1.72575C0.359588 1.60374 0.332002 1.47252 0.330848 1.33974C0.329694 1.20696 0.354996 1.07529 0.405277 0.952389C0.455558 0.829493 0.529811 0.71784 0.623704 0.623948C0.717597 0.530055 0.829248 0.455802 0.952145 0.405521C1.07504 0.35524 1.20672 0.329939 1.3395 0.331092C1.47228 0.332246 1.6035 0.359833 1.7255 0.412242C1.84751 0.464651 1.95785 0.540832 2.0501 0.636343L7.0001 5.58634L11.9501 0.636343C12.1387 0.454185 12.3913 0.35339 12.6535 0.355669C12.9157 0.357947 13.1665 0.463116 13.3519 0.648524C13.5373 0.833932 13.6425 1.08474 13.6448 1.34694C13.6471 1.60914 13.5463 1.86174 13.3641 2.05034L7.7071 7.70734Z"
                          fill="white"
                        />
                      </svg>
                    </span>
                  </div>
                  <div
                    className={`text-base  overflow-hidden duration-300  ${showService ? "max-h-[450px]" : "max-h-0"
                      }`}
                  >
                    <div className="flex flex-col border-t border-b border-primary py-5 mb-5 gap-5 !text-white hover:!text-primary">
                      <Link to="/ui-ux-design">UI/UX design</Link>
                      <Link to="/web-development">Web development</Link>
                      <Link to="/app-development">App development</Link>
                      <Link to="/web3-development">Web3 Development</Link>
                      <Link to="/fintech-accounting">Fintech & Accounting</Link>
                      <Link to="/crm-development">CRM Development</Link>
                      <Link to="/testing-auditing">Testing & Auditing</Link>
                      {/* <Link to="/ecommerce-shopify">Ecommerce & Shopify</Link> */}
                      <Link to="/ai-development">Al Development</Link>
                    </div>
                  </div>
                </li>
                <li>
                  <Link
                    to="/portfolio"
                    className={`h-[28px] md:h-[56px] overflow-hidden inline-block group leading-[1] ${location.pathname === "/portfolio"
                      ? "!text-primary"
                      : undefined
                      }`}
                  >
                    <div className="group-hover:-translate-y-[28px] md:group-hover:-translate-y-[56px] duration-300 will-change-transform !text-white hover:!text-primary">
                      <div>Portfolio</div>
                      <div>Portfolio</div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/contact-us"
                    className={`h-[28px] md:h-[56px] overflow-hidden inline-block group leading-[1] ${location.pathname === "/contact-us"
                      ? "!text-primary"
                      : undefined
                      }`}
                  >
                    <div className="group-hover:-translate-y-[28px] md:group-hover:-translate-y-[56px] duration-300 will-change-transform !text-white hover:!text-primary">
                      <div>Contact</div>
                      <div>Contact</div>
                    </div>
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog"
                    className={`h-[28px] md:h-[56px] overflow-hidden inline-block group leading-[1] ${location.pathname === "/blog"
                      ? "!text-primary"
                      : undefined
                      }`}
                  >
                    <div className="group-hover:-translate-y-[28px] md:group-hover:-translate-y-[56px] duration-300 will-change-transform !text-white hover:!text-primary">
                      <div>Blog</div>
                      <div>Blog</div>
                    </div>
                  </Link>
                </li>
              </ul>
              <div className="flex-[1.4] min-w-[500px] max-md:hidden">
                <h3 className="text-primary mb-5">Services</h3>
                <div
                  className="max-h-[62vh] overflow-y-auto menu-scroll"
                  data-lenis-prevent
                >
                  <div className="cards gap-[35px] flex flex-col w-full pr-5 !mt-0">
                    <Link
                      to="/ui-ux-design"
                      className="card menu-card small flex"
                    >
                      <div>
                        <h4 className="text-[28px] max-w-[170px] ">
                          UI/UX design
                        </h4>
                      </div>
                      <div className="images absolute w-[40%] right-0 mt-3 h-[150px]">
                        <img
                          src="/images-new/home/our-work/ui-ux1.png"
                          alt="UI/UX Design 1"
                          className="-left-10 z-[2]"
                        />
                        <img
                          src="/images-new/home/our-work/ui-ux2.png"
                          alt="UI/UX Design 2"
                          className="-left-5 z-[1]"
                        />
                        <img
                          src="/images-new/home/our-work/ui-ux3.png"
                          alt="UI/UX Design 3"
                          className="absolute left-0 top-8"
                        />
                      </div>
                    </Link>
                    <Link
                      to="/web-development"
                      className="card menu-card small flex"
                    >
                      <div>
                        <h4 className="text-[28px] max-w-[170px] ">
                          Web development
                        </h4>
                      </div>
                      <div className="images absolute w-[40%] right-0 mt-3 h-[150px]">
                        <img
                          src="/images-new/home/our-work/web-dev1.png"
                          alt="Web Development 1"
                          className="-left-10 z-[2]"
                        />
                        <img
                          src="/images-new/home/our-work/web-dev2.png"
                          alt="Web Development 2"
                          className="-left-5 z-[1]"
                        />
                        <img
                          src="/images-new/home/our-work/web-dev3.png"
                          alt="Web Development 3"
                          className="absolute left-0 top-8"
                        />
                      </div>
                    </Link>
                    <Link
                      to="/app-development"
                      className="card menu-card small flex"
                    >
                      <div>
                        <h4 className="text-[28px] max-w-[170px] ">
                          App development
                        </h4>
                      </div>
                      <div className="images absolute w-[40%] right-0 mt-3 h-[150px]">
                        <img
                          src="/images-new/home/our-work/app-dev1.png"
                          alt="App Development 1"
                          className="-left-10 z-[2]"
                        />
                        <img
                          src="/images-new/home/our-work/app-dev2.png"
                          alt="App Development 2"
                          className="-left-5 z-[1]"
                        />
                        <img
                          src="/images-new/home/our-work/app-dev3.png"
                          alt="App Development 3"
                          className="absolute left-0 top-8"
                        />
                      </div>
                    </Link>
                    <Link
                      to="/web3-development"
                      className="card menu-card small flex"
                    >
                      <div>
                        <h4 className="text-[28px] max-w-[170px] ">
                          Web3 Development
                        </h4>
                      </div>
                      <div className="images absolute w-[40%] right-0 mt-3 h-[150px]">
                        <img
                          src="/images-new/home/our-work/blockchain1.jpg"
                          alt="Web3 Development 1"
                          className="-left-10 z-[2]"
                        />
                        <img
                          src="/images-new/home/our-work/blockchain2.jpg"
                          alt="Web3 Development 2"
                          className="-left-5 z-[1]"
                        />
                        <img
                          src="/images-new/home/our-work/blockchain3.jpg"
                          alt="Web3 Development 3"
                          className="absolute left-0 top-8"
                        />
                      </div>
                    </Link>
                    <Link
                      to="/fintech-accounting"
                      className="card menu-card small flex"
                    >
                      <div>
                        <h4 className="text-[28px] max-w-[170px] ">
                          Fintech & Accounting
                        </h4>
                      </div>
                      <div className="images absolute w-[40%] right-0 mt-3 h-[150px]">
                        <img
                          src="/images-new/home/our-work/fintech1.png"
                          alt="Fintech & Accounting Websites 1"
                          className="-left-10 z-[2]"
                        />
                        <img
                          src="/images-new/home/our-work/fintech2.png"
                          alt="Fintech & Accounting Websites 2"
                          className="-left-5 z-[1]"
                        />
                        <img
                          src="/images-new/home/our-work/fintech3.png"
                          alt="Fintech & Accounting Websites 3"
                          className="absolute left-0 top-8"
                        />
                      </div>
                    </Link>
                    <Link
                      to="/crm-development"
                      className="card menu-card small flex"
                    >
                      <div>
                        <h4 className="text-[28px] max-w-[170px] ">
                          CRM Development
                        </h4>
                      </div>
                      <div className="images absolute w-[40%] right-0 mt-3 h-[150px]">
                        <img
                          src="/images-new/home/our-work/crm1.png"
                          alt="CRM Development 1"
                          className="-left-10 z-[2]"
                        />
                        <img
                          src="/images-new/home/our-work/crm2.png"
                          alt="CRM Development 2"
                          className="-left-5 z-[1]"
                        />
                        <img
                          src="/images-new/home/our-work/crm3.png"
                          alt="CRM Development 3"
                          className="absolute left-0 top-8"
                        />
                      </div>
                    </Link>
                    <Link
                      to="/testing-auditing"
                      className="card menu-card small flex"
                    >
                      <div>
                        <h4 className="text-[28px] max-w-[170px] ">
                          Testing & Auditing
                        </h4>
                      </div>
                      <div className="images absolute w-[40%] right-0 mt-3 h-[150px]">
                        <img
                          src="/images-new/home/our-work/testing1.png"
                          alt="Testing and Auditing Web Services 1"
                          className="-left-10 z-[2]"
                        />
                        <img
                          src="/images-new/home/our-work/testing2.png"
                          alt="Testing and Auditing Web Services 2"
                          className="-left-5 z-[1]"
                        />
                        <img
                          src="/images-new/home/our-work/testing3.png"
                          alt="Testing and Auditing Web Services 3"
                          className="absolute left-0 top-8"
                        />
                      </div>
                    </Link>
                    {/* <Link
                      to="/ecommerce-shopify"
                      className="card menu-card small flex"
                    >
                      <div>
                        <h4 className="text-[28px] max-w-[170px] ">
                          Ecommerce & Shopify
                        </h4>
                      </div>
                      <div className="images absolute w-[40%] right-0 mt-3 h-[150px]">
                        <img
                          src="/images-new/home/our-work/ecom1.png"
                          alt="Ecommerce & Shopify Services 1"
                          className="-left-10 z-[2]"
                        />
                        <img
                          src="/images-new/home/our-work/ecom2.png"
                          alt="Ecommerce & Shopify Services 2"
                          className="-left-5 z-[1]"
                        />
                        <img
                          src="/images-new/home/our-work/ecom3.png"
                          alt="Ecommerce & Shopify Services 3"
                          className="absolute left-0 top-8"
                        />
                      </div>
                    </Link> */}
                    <Link
                      to="/ai-development"
                      className="card menu-card small flex"
                    >
                      <div>
                        <h4 className="text-[28px] max-w-[170px] ">
                          Ai Development
                        </h4>
                      </div>
                      <div className="images absolute w-[40%] right-0 mt-3 h-[150px]">
                        <img
                          src="/images-new/home/our-work/ai-dev1.png"
                          alt="Ai Development Services 1"
                          className="-left-10 z-[2]"
                        />
                        <img
                          src="/images-new/home/our-work/ai-dev2.png"
                          alt="Ai Development Services 2"
                          className="-left-5 z-[1]"
                        />
                        <img
                          src="/images-new/home/our-work/ai-dev3.png"
                          alt="Ai Development Services 3"
                          className="absolute left-0 top-8"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="menu-footer mt-[20px] md:flex justify-between mb-5">
              <div className="social">
                <ul className="flex gap-5">
                  <li>
                    <Link
                      to="https://www.instagram.com/solusent.io/?fbclid=IwZXh0bgNhZW0CMTEAAR1VhZ4h6fbhmYMOt7OjU2RNA0XlYpcrSbIBQ15IFdrgylWKcYTU-uwoUT8_aem_sDdkrzDnrh-baD3JgtLoCg" target='_blank'
                      className="h-[40px] overflow-hidden inline-block group "
                    >
                      <div className="group-hover:-translate-y-[40px] duration-300 flex flex-col gap-[2px]">
                        <span className="[&>span]:h-5 p-2 border rounded-full border-white">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5.8 0H14.2C17.4 0 20 2.6 20 5.8V14.2C20 15.7383 19.3889 17.2135 18.3012 18.3012C17.2135 19.3889 15.7383 20 14.2 20H5.8C2.6 20 0 17.4 0 14.2V5.8C0 4.26174 0.61107 2.78649 1.69878 1.69878C2.78649 0.61107 4.26174 0 5.8 0ZM5.6 2C4.64522 2 3.72955 2.37928 3.05442 3.05442C2.37928 3.72955 2 4.64522 2 5.6V14.4C2 16.39 3.61 18 5.6 18H14.4C15.3548 18 16.2705 17.6207 16.9456 16.9456C17.6207 16.2705 18 15.3548 18 14.4V5.6C18 3.61 16.39 2 14.4 2H5.6ZM15.25 3.5C15.5815 3.5 15.8995 3.6317 16.1339 3.86612C16.3683 4.10054 16.5 4.41848 16.5 4.75C16.5 5.08152 16.3683 5.39946 16.1339 5.63388C15.8995 5.8683 15.5815 6 15.25 6C14.9185 6 14.6005 5.8683 14.3661 5.63388C14.1317 5.39946 14 5.08152 14 4.75C14 4.41848 14.1317 4.10054 14.3661 3.86612C14.6005 3.6317 14.9185 3.5 15.25 3.5ZM10 5C11.3261 5 12.5979 5.52678 13.5355 6.46447C14.4732 7.40215 15 8.67392 15 10C15 11.3261 14.4732 12.5979 13.5355 13.5355C12.5979 14.4732 11.3261 15 10 15C8.67392 15 7.40215 14.4732 6.46447 13.5355C5.52678 12.5979 5 11.3261 5 10C5 8.67392 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5ZM10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span className="[&>span]:h-5 p-2 border rounded-full bg-primary border-primary">
                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                          >
                            <path
                              d="M5.8 0H14.2C17.4 0 20 2.6 20 5.8V14.2C20 15.7383 19.3889 17.2135 18.3012 18.3012C17.2135 19.3889 15.7383 20 14.2 20H5.8C2.6 20 0 17.4 0 14.2V5.8C0 4.26174 0.61107 2.78649 1.69878 1.69878C2.78649 0.61107 4.26174 0 5.8 0ZM5.6 2C4.64522 2 3.72955 2.37928 3.05442 3.05442C2.37928 3.72955 2 4.64522 2 5.6V14.4C2 16.39 3.61 18 5.6 18H14.4C15.3548 18 16.2705 17.6207 16.9456 16.9456C17.6207 16.2705 18 15.3548 18 14.4V5.6C18 3.61 16.39 2 14.4 2H5.6ZM15.25 3.5C15.5815 3.5 15.8995 3.6317 16.1339 3.86612C16.3683 4.10054 16.5 4.41848 16.5 4.75C16.5 5.08152 16.3683 5.39946 16.1339 5.63388C15.8995 5.8683 15.5815 6 15.25 6C14.9185 6 14.6005 5.8683 14.3661 5.63388C14.1317 5.39946 14 5.08152 14 4.75C14 4.41848 14.1317 4.10054 14.3661 3.86612C14.6005 3.6317 14.9185 3.5 15.25 3.5ZM10 5C11.3261 5 12.5979 5.52678 13.5355 6.46447C14.4732 7.40215 15 8.67392 15 10C15 11.3261 14.4732 12.5979 13.5355 13.5355C12.5979 14.4732 11.3261 15 10 15C8.67392 15 7.40215 14.4732 6.46447 13.5355C5.52678 12.5979 5 11.3261 5 10C5 8.67392 5.52678 7.40215 6.46447 6.46447C7.40215 5.52678 8.67392 5 10 5ZM10 7C9.20435 7 8.44129 7.31607 7.87868 7.87868C7.31607 8.44129 7 9.20435 7 10C7 10.7956 7.31607 11.5587 7.87868 12.1213C8.44129 12.6839 9.20435 13 10 13C10.7956 13 11.5587 12.6839 12.1213 12.1213C12.6839 11.5587 13 10.7956 13 10C13 9.20435 12.6839 8.44129 12.1213 7.87868C11.5587 7.31607 10.7956 7 10 7Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="https://www.linkedin.com/company/solusent/" target='_blank'
                      className="h-9 overflow-hidden inline-block group"
                    >
                      <div className="group-hover:-translate-y-[40px] duration-300 flex flex-col gap-1 ">
                        <span className="[&>span]:h-5 p-2 border rounded-full border-white">
                          <svg
                            width="20"
                            height="18"
                            viewBox="0 0 20 18"
                            fill="none"
                          >
                            <path
                              d="M4.93994 2.00002C4.93968 2.53046 4.72871 3.03906 4.35345 3.41394C3.97819 3.78883 3.46937 3.99929 2.93894 3.99902C2.40851 3.99876 1.89991 3.78779 1.52502 3.41253C1.15014 3.03727 0.939676 2.52846 0.939942 1.99802C0.940207 1.46759 1.15117 0.958988 1.52644 0.584103C1.9017 0.209218 2.41051 -0.00124153 2.94094 -0.000976312C3.47137 -0.000711096 3.97998 0.210257 4.35486 0.585517C4.72975 0.960777 4.94021 1.46959 4.93994 2.00002ZM4.99994 5.48002H0.999942V18H4.99994V5.48002ZM11.3199 5.48002H7.33994V18H11.2799V11.43C11.2799 7.77002 16.0499 7.43002 16.0499 11.43V18H19.9999V10.07C19.9999 3.90002 12.9399 4.13002 11.2799 7.16002L11.3199 5.48002Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        <span className="[&>span]:h-5 p-2 border rounded-full bg-primary border-primary">
                          <svg
                            width="20"
                            height="18"
                            viewBox="0 0 20 18"
                            fill="none"
                          >
                            <path
                              d="M4.93994 2.00002C4.93968 2.53046 4.72871 3.03906 4.35345 3.41394C3.97819 3.78883 3.46937 3.99929 2.93894 3.99902C2.40851 3.99876 1.89991 3.78779 1.52502 3.41253C1.15014 3.03727 0.939676 2.52846 0.939942 1.99802C0.940207 1.46759 1.15117 0.958988 1.52644 0.584103C1.9017 0.209218 2.41051 -0.00124153 2.94094 -0.000976312C3.47137 -0.000711096 3.97998 0.210257 4.35486 0.585517C4.72975 0.960777 4.94021 1.46959 4.93994 2.00002ZM4.99994 5.48002H0.999942V18H4.99994V5.48002ZM11.3199 5.48002H7.33994V18H11.2799V11.43C11.2799 7.77002 16.0499 7.43002 16.0499 11.43V18H19.9999V10.07C19.9999 3.90002 12.9399 4.13002 11.2799 7.16002L11.3199 5.48002Z"
                              fill="black"
                            />
                          </svg>
                        </span>
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.facebook.com/profile.php?id=61553577256309&locale=en_GB" target='_blank'
                      className="h-9 overflow-hidden inline-block group "
                    >
                      <div className="group-hover:-translate-y-[40px] duration-300 flex flex-col gap-1 ">
                        <span className="[&>span]:h-5 p-2 border rounded-full border-white">
                          <svg
                            id="Layer_1"
                            enableBackground="new 0 0 100 100"
                            height="18"
                            viewBox="0 0 100 100"
                            width="18"
                          >
                            <g id="_x30_1._Facebook">
                              <path
                                id="Icon_11_"
                                fill="white"
                                d="m40.4 55.2c-.3 0-6.9 0-9.9 0-1.6 0-2.1-.6-2.1-2.1 0-4 0-8.1 0-12.1 0-1.6.6-2.1 2.1-2.1h9.9c0-.3 0-6.1 0-8.8 0-4 .7-7.8 2.7-11.3 2.1-3.6 5.1-6 8.9-7.4 2.5-.9 5-1.3 7.7-1.3h9.8c1.4 0 2 .6 2 2v11.4c0 1.4-.6 2-2 2-2.7 0-5.4 0-8.1.1-2.7 0-4.1 1.3-4.1 4.1-.1 3 0 5.9 0 9h11.6c1.6 0 2.2.6 2.2 2.2v12.1c0 1.6-.5 2.1-2.2 2.1-3.6 0-11.3 0-11.6 0v32.6c0 1.7-.5 2.3-2.3 2.3-4.2 0-8.3 0-12.5 0-1.5 0-2.1-.6-2.1-2.1 0-10.5 0-32.4 0-32.7z"
                              />
                            </g>
                          </svg>
                        </span>
                        <span className="[&>span]:h-5 p-2 border rounded-full bg-primary border-primary">
                          <svg
                            id="Layer_1"
                            enableBackground="new 0 0 100 100"
                            height="18"
                            viewBox="0 0 100 100"
                            width="18"
                          >
                            <g id="_x30_1._Facebook">
                              <path
                                id="Icon_11_"
                                fill="black"
                                d="m40.4 55.2c-.3 0-6.9 0-9.9 0-1.6 0-2.1-.6-2.1-2.1 0-4 0-8.1 0-12.1 0-1.6.6-2.1 2.1-2.1h9.9c0-.3 0-6.1 0-8.8 0-4 .7-7.8 2.7-11.3 2.1-3.6 5.1-6 8.9-7.4 2.5-.9 5-1.3 7.7-1.3h9.8c1.4 0 2 .6 2 2v11.4c0 1.4-.6 2-2 2-2.7 0-5.4 0-8.1.1-2.7 0-4.1 1.3-4.1 4.1-.1 3 0 5.9 0 9h11.6c1.6 0 2.2.6 2.2 2.2v12.1c0 1.6-.5 2.1-2.2 2.1-3.6 0-11.3 0-11.6 0v32.6c0 1.7-.5 2.3-2.3 2.3-4.2 0-8.3 0-12.5 0-1.5 0-2.1-.6-2.1-2.1 0-10.5 0-32.4 0-32.7z"
                              />
                            </g>
                          </svg>
                        </span>
                      </div>
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="font-normal max-md:mt-3">
                <Link
                  to="mailto:contact@solusent.com"
                  className="inline-block text-white"
                >
                  contact@solusent.com
                </Link>
                <span className="text-[rgba(255,255,255,0.5)] inline-block px-2">
                  {" "}
                  |{" "}
                </span>
                <Link
                  to="tel:02031938835"
                  className="text-primary hover:underline"
                >
                  0203 1938 835
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
